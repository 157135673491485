import React from "react"
import { Container, Header, Section, Footer } from "../components/Lib"
import SpecialPageHeader from "../components/SpecialPageHeader"
import SpecialPageMain from "../components/SpecialPageMain"
import { ReactComponent as PodcasterSVG } from "../assets/images/podcaster.svg"
import SEO from "../components/seo"
const About = () => {
  return (
    <Container>
      <SEO
        title="درباره ما"
        description="ما در پادکست فارسی بایوکست، داستان زندگی افراد موفقی رو تعریف می‌کنیم که
            بر موانع و سختی‌های زندگیشون چیره شدن و تاثیر بزرگی از خودشون به جا
            گذاشتند."
        keywords={["بایوکست", "پادکست زندگینامه", "زندگینامه افراد موفق"]}
      />
      <Header className="header--lightgray" />
      <section className="special_page">
        <SpecialPageHeader
          title="About Us"
          subtitle="درباره ما"
          svg={<PodcasterSVG />}
        />
        <SpecialPageMain>
          <p>
            من{" "}
            <a
              class="link"
              href="https://twitter.com/khashayarnoor"
              target="_blank"
            >
              خشایار نور{" "}
            </a>
            هستم و از آبان ۱۳۹۷ با همکاری تعدادی از دوستانم شروع به ساخت پادکست
            فارسی بایوکست کردیم. بایوکست داستان زندگی افراد رو تعریف می‌کنه.
            داستان زندگینامه افرادی که شنیدنش می‌تونه برای ما خیلی جذاب باشه.
            <br />
            ما داستان زندگی کسانی رو انتخاب می‌کنیم که تاثیر بزرگی از خودشون به
            جا گذاشته باشن. پس امیدواریم ضمن لذت بردن از شنیدن هر اپیزود، زندگی
            هر کدوم از شخصیت‌ها الهام‌بخش ما باشه.
          </p>
          <p>
            اگر دوست داری به بایوکست کمکی بکنی به
            <a className="link" href="mailto:hi@biocastpodcast.ir">
              {" "}
              hi@biocastpodcast.ir{" "}
            </a>
            .ایمیل بزن
          </p>
        </SpecialPageMain>
      </section>
      <Footer beforeColor="white" />
    </Container>
  )
}

export default About
