import React from "react"

const SpecialPageMain = ({ children, className, ...props }) => {
  return (
    <main {...props} className={`special_page__main ${className}`}>
      {children}
    </main>
  )
}

export default SpecialPageMain
