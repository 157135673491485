import React from "react"

const SpecialPageHeader = ({ title, subtitle, svg, className, ...props }) => {
  return (
    <header {...props} className={`special_page__header ${className}`}>
      <h2>{title}</h2>
      <h1>{subtitle}</h1>
    </header>
  )
}

export default SpecialPageHeader
